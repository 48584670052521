/* .LazyLoadVideo--container {
    opacity: 0;
    animation: fadeIn 2s forwards; // Animation name, duration, fill-mode
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
} 
 */

.LazyLoadVideo--container {
    padding: 20px;
    color: white;
    border-radius: 10px;
    /* Initially positioned off-screen to the left */
    transform: translateX(-100%);
    animation: slideIn 0.5s forwards; /* Animation name, duration, fill-mode */
  }
  
  /* Keyframes for the slide-in effect */
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

.LazyLoadVideo--container {
    display: flex;
    justify-content: space-between;
    margin: 8rem 0;

    /* Mobile version: single column layout */
    @media (max-width: 768px) {
        display: block;

        .learning-item {
            flex-direction: column;
        }
    }

    h2 {
        margin: 0;
        font-size: x-large;
    }

    /* Mobile version: single column layout */
    @media (max-width: 768px) {
        h2 {
            margin: 2rem 0;
        }
    }

}