.About-container {
  padding-top: 8rem;
  padding-bottom: 10rem;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
  
  h2 {
    margin:  0;
  }

  header {
    margin-bottom: 8rem;
  }

  .About-section1 {
    display: grid;
    grid-template-columns: 2fr 1.5fr; // Creates two columns of equal width
    align-items: center; // Aligns items vertically in the center
    gap: 20px; // Adjusts the gap between grid items

    @media (max-width: 768px) {
      // Adjusts for smaller screens
      grid-template-columns: 1fr; // Stacks items in a single column on smaller screens
    }
  }

  .About-title {
    font-size: 4rem;
  }

  .About-picture {
    width: 75%; // Define the width of the container
    height: 600px; // Define the height of the container
    overflow: hidden; // Ensures the image doesn't overflow the container
    display: flex; // Utilizes Flexbox for additional centering control if needed
    justify-content: center; // Centers the content horizontally in the container
    align-items: center; // Centers the content vertically in the container
  }

  .About-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover; // This will crop and cover the area, maintaining aspect ratio
    object-position: center; // Keeps the image centered
  }

  section {
    width: 60%;
    margin: 0 auto;
    font-size: 2rem;
    @media (max-width: 768px) {
      // Adjusts for smaller screens
      grid-template-columns: 1fr; // Stacks items in a single column on smaller screens
      width: 90%;
    }
  }
}