.fade {
  animation: fadeInOut 3000ms ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.projectDetails--container {
  width: 100vw;
  margin: 0;
  text-align: center;

  /* Desktop styles */
  @media (min-width: 768px) {
    width: 70vw;
    margin: 0 auto;
    text-align: left;
  }

  header {
    margin-top: 10rem;
  }

  header h1,
  header .project-main-picture {
    text-align: center; // Center text and images
  }

  header img {
    width: 100%; // Set image width to 50% of its container
    margin: 0 auto; // Center the image
    display: block; // Needed to enable margin auto to work
  }

  .about-project-title {
    width: 30%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .project-description {
    text-align: left;
    width: 70%;

    /* Hide overflowed content */
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  .heading {
    font-weight: bolder;
    color: rgb(109, 102, 102)
  }

  section {
    margin: 0 auto; // Center
    overflow: hidden;

    /* Hide overflowed content */
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .project-about-content {
    margin-top: 0;
    margin-bottom: 2rem;
  } 

  .prev-project,
  .next-project {
    display: inline-block; // Ensure the element is suitable for border animation
    position: relative; // Positioning context for the pseudo-element
    padding: 10px; // Adjust padding as needed

    &:hover {
      color: #1085e5; // Change the color to whatever you want
    }
  }

  .section-about-the-project {
    display: block;
    margin: 5rem 0;
    text-align: center;

  }

  /* Media query for desktop screens */
  @media (min-width: 768px) {
    .section-about-the-project {
      /* Apply flex layout for desktop screens */
      display: flex;
      justify-content: flex-start;
      text-align: left;
    }

    /* Additional desktop-specific styles can go here */
    /* For example, you might want to adjust margins or widths of child elements */
  }
}