.home-container {
    margin-top: 10rem;
    padding-left: 6rem;

    @media (max-width: 768px) {
        padding-left: 1rem;
        width: 90%;
    }

    header {
        margin: 2rem auto;
        width: 90%;
    }

    .home-title {
        text-align: left;
        font-size: 8rem;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 3rem;
        }
    }

    .home-subtitle {
        font-size: 2rem;
    }

    .home-selected-works {
        font-size: 2rem;
        text-align: left;
    }

    .filters {
        display: flex; // Ensures the buttons are laid out in a row
        flex-wrap: wrap; // Allows buttons to wrap to the next line if space is constrained
        gap: 10px; // Creates space between buttons both vertically and horizontally
        margin-bottom: 1rem; // Adds some space below the filter buttons
    }

    .tag-button {
        padding: 8px 16px; // Adjust padding to suit your design
        font-size: 1rem; // Sets a comfortable font size, adjust as needed
        background-color: #000000; // Example button color, choose what fits your design
        color: white; // Text color that contrasts with the button background
        border: none; // Removes the default border
        border-radius: 5px; // Adds rounded corners for a modern look
        cursor: pointer; // Changes the cursor to indicate the button is clickable
        transition: background-color 0.3s ease; // Smooth transition for hover effect

        &:hover {
            background-color: #0056b3; // Darkens the button on hover for visual feedback
        }

        &:focus {
            outline: none; // Removes the default focus outline to maintain the design
            box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); // Optional: Adds a custom focus style
        }
    }

    .tag-button-selected {
        background-color: #0056b3;
        /* Green background for selected */
        color: white;
        /* White text for contrast */
    }

    .home-grid-projects-container {
        display: grid;
        width: 90%;
        /* Set the width of the grid container to 90% */
        margin: 2rem auto;
        /* Center the grid container horizontally */
        grid-template-columns: 1fr 1fr;
        /* Adjust the minmax for your preferred item width */
        grid-gap: 20px;
        /* Adjust the gap as needed */
        align-items: start;
        /* Align items to the start of the grid area */
    }

    /* Ensuring content within each project div fills the available space */
    .home-grid-projects-container>div {
        display: flex;
        flex-direction: column;
    }

    .project-item {
        border: 1px solid #ccc; // Adds a light grey border
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
        padding: 20px; // Adds spacing inside the card
        border-radius: 8px; // Gives rounded corners for a smoother look
        background-color: #fff; // Ensures a clean background
        transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effects

        &:hover {
            transform: translateY(-5px); // Moves the card up slightly on hover
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // Makes the shadow deeper on hover
        }

        h2 {
            margin-top: 0; // Adjusts spacing at the top of the card, if necessary
            font-size: 1.5rem; // Adjust the font size as needed
        }

        p {
            color: #666; // Lightens the text color for readability
            font-size: 1rem; // Adjust the font size as needed
            font-weight: 400;
        }

        .project-tags {
            position: relative;
            bottom: 0px;
            font-size: 0.75rem;

            span {
                text-decoration: underline;
                margin: 0 .3rem;
            }
        }
    }

    .project-picture {
        margin: 2rem 0 2rem 0;

        img {
            width: 100%;
            height: auto;
        }
    }
}

/* Example of a media query for smaller screens - adjust as necessary */
@media (max-width: 600px) {
    .home-container {
        .home-grid-projects-container {
            grid-template-columns: 1fr;
            /* 1 column layout for small screens */
        }
    }
}