 /* Base styles for mobile: items stack vertically */
.project-infos-container {
     display: flex;
     flex-direction: column;
     gap: 20px;

     h2 {
          font-size: x-large;
     }
}

/* Styles for desktop: align items in a row */
@media screen and (min-width: 1024px) {

     /* Adjust breakpoint as needed */
    .project-infos-container{
         flex-direction: row;
         justify-content: space-between;
         /* Distributes space between items */
         flex-wrap: wrap;
         /* Allows items to wrap onto the next line if not enough space */
     }

     .section-project-infos-item,
     .section-project-infos-items {
         flex: 1;
         /* Makes items flexible to fill the container */
         margin: 0;
     }

     .section-about-the-project {
          h2 {
               margin: 0;
               font-size: x-large;
          }
     }
}