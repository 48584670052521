.LazyLoadImage--container {
    padding: 20px;
    border-radius: 10px;
    transform: translateX(-100%);
    animation: slideIn 0.5s forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  