.scroll-indicator-container {
    .scroll-indicator {
        position: fixed; // Or absolute, depending on your layout
        bottom: 20px;
        left: 10%;
        transform: translateX(-50%);
        z-index: 1000; // Ensure it's above other content
        background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
        color: white;
        padding: 10px;
        border-radius: 20px;
        font-size: 16px;
        cursor: pointer;
    }
}