.project-navigation-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em; // Optionally, adjust the font size to make text smaller
  margin-bottom: 3rem;
  padding: 8rem 0;

  .prev-project,
  .next-project {
    display: flex;
    flex-direction: column; // Change direction to column for stacking
    align-items: center; // Center align the items
    text-align: center; // Ensure text is centered under the image

    img {
      width: 300px; // Adjust image size as needed
      height: auto; // Maintain aspect ratio
      margin-bottom: 10px; // Space between image and text
    }

    /* Media query for screens that are 600px wide or less */
    @media (max-width: 600px) {
      img {
        width: 100px;
      }
    }
  }
}