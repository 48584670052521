.navigation-container {
  nav {
    margin-top: 10px;
    /* Adjust the margin as needed */
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    /* Adjust the margin as needed */
  }
  
  // In navigation.scss or your relevant stylesheet

  .burger-menu {
    position: fixed;
    top: 10px;
    right: 33px;
    cursor: pointer;
    z-index: 1000;
    font-size: 2rem;
  }

  .menu-items {
    position: fixed;
    z-index: 99;
    top: 0;
    right: -250px; // Initially positioned off-screen
    background: white;
    height: fit-content;
    width: 208px; // Set a fixed width for the menu
    overflow: hidden;
    transition: right 0.3s ease; // Transition the right property for sliding effect
  }

  .menu-items.open {
    right: 20px; // Bring the menu on-screen
    border-bottom-left-radius: 8px;
    padding-left: 1rem;
  }

  .menu-link {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: black;
  }

  .active {
    color: #3498db;
  }

}