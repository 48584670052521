.learning-experiences-container {
    margin-bottom: 7rem;
    /* Base styles for mobile: items stack vertically */
    .section-project-infos {
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* Adjust gap between items as needed */
    }

    /* Styles for desktop: align items in a row */
    @media screen and (min-width: 1024px) {

        /* Adjust breakpoint as needed */
        .section-project-infos {
            flex-direction: row;
            justify-content: space-between;
            /* Distributes space between items */
            flex-wrap: wrap;
            /* Allows items to wrap onto the next line if not enough space */
        }

        .section-project-infos-item,
        .section-project-infos-items {
            flex: 1;
            /* Makes items flexible to fill the container */
            margin: 0;
        }
    }

    h2 {
        font-size: x-large;
        padding: .5rem;
    }


    .title-experience {
        /* font-size: 1.5rem; */ /* Example size */
        color: #333; /* Dark grey text */
    }
    
    .description-experience {
        font-size: 1rem;
        color: #666; /* Lighter grey text */
    }
    
    h3 {
        font-size: large;
        padding: .5rem;
    }


    .learning-experiences-container {
        max-width: 1200px; /* or your desired max width */
        margin: auto;
        padding: 20px;
    }
    
    .learning-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    
    .title-experience, .description-experience {
        flex: 1; /* Makes both items take equal width */
        padding: 10px;
        text-align: left;
    }
    
    /* Mobile version: single column layout */
    @media (max-width: 768px) {
        .learning-item {
            flex-direction: column;
        }
        .title-experience, .description-experience {
            text-align: center;
        }
    }
    
}