.technicalSheet-container {
  display: block;
  text-align: center;
  /* Styles for desktop: align items in a row */
  @media screen and (min-width: 1024px) {
    display: flex;
    text-align: left;
  }
  ul {
      margin: 1rem 5rem;
      list-style-type: none; /* Removes the default list bullet */
      padding: 0;
     /*  margin: 0; */
      display: grid;
    }
    
    li {
      font-family: 'Arial', sans-serif; /* Use a modern, sans-serif font */
      font-size: 16px; /* Adequate font size for readability */
      color: #333; /* Dark grey color for text */
      padding: 8px 0; /* Add some vertical padding for spacing between items */
    } 
}