
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

.App {
  width:100%;
  overflow-x: hidden;

  a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s ease;
    /* Add a smooth transition for color change */
  }
  
  a:hover {
    color: #555;
    /* Change the color on hover */
  }
  
  /* Add style for the active link */
  a.active {
    border-bottom: 2px solid #333;
    /* Add underline for the active link */
  }
  
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  transform-origin: top;
}


